<template>
  <div>
    <div class="footer-area-wrapper body-footer">
      <div class="footer-area section-space--ptb_90">
        <div class="container" >
          <div class="row footer-widget-wrapper">
            
            <div class="col-lg-3 col-md-6 footer-widget">
              <h6 class="footer-widget__title mb-20">
                <a href="https://www.cbre.co.jp/ja-jp/about" target="_blank"
                  ><div class="lang" v-html="lan.cbre"></div>
                </a>
              </h6>
              <ul class="footer-widget__list">
                 <li>
                  <a
                    href="https://www.cbre.co.jp"
                    target="_blank"
                    ><div class="lang" v-html="lan.huishe"></div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.cbre.co.jp/ja-jp/about/japan-privacy-policy"
                    target="_blank"
                    ><div class="lang" v-html="lan.privacy_policy"></div>
                  </a>
                </li>
               
                <!--<li>
                  <a
                    href="https://www.cbre.co.jp/ja-jp/about/japan-term-of-use"
                    target="_blank"
                    ><div class="lang" v-html="lan.term_of_use"></div>
                  </a>
                </li>-->
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 footer-widget">
              <h6 class="footer-widget__title mb-20">
                  CREIS JAPAN
                </h6>
              <ul class="footer-widget__list">
                <li>
                 
                    <a
                    href="mailto:creissupport@cbre.co.jp"
                    target="_blank"
                    > {{lan.creis_japan_contact_us}}</a>
                    
                 
                </li>
                <li>
                 
                 <a
                 href="/faq"
                 target="_blank"
                 > {{lan.creis_japan_faq}}</a>
             </li>
                <li>
                  <a
                    :href="cur_lan=='jp' ?'https://cdn.creis.jp/contract/TermsofUseJP_20230110.pdf':'https://cdn.creis.jp/contract/TermsofUseEN_20230110.pdf'"
                    target="_blank"
                    ><div class="lang" v-html="lan.cbre_member_term"></div> </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
              <div class="twitter-feed-slider">
                <div class="swiper-container twitter-feed-slider__container">
                  <div class="swiper-wrapper twitter-feed-slider__wrapper">
                    <div class="swiper-slide twitter-feed-slider__single"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright-area border-top section-space--ptb_30">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 text-center text-md-left">
              <span class="copyright-text">
                Copyright © {{year}} <b>CBRE</b>. All right reserved.
              </span>
            </div>
            <div class="col-md-6 text-center text-md-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer-area-wrapper {
  padding: 25px 0;
  background: #fff;
}
.footer-copyright-area {
  padding-top: 25px;
}
.body-footer .container{
  width:90%;max-width:95% !important;
}
</style>
<script>
export default {
  name: "Footer",
 
  data() {
    return {
      cur_lan: localStorage.getItem("cur_lan") || "jp",
      lan: JSON.parse(localStorage.getItem("creis_language_data"))["menu_data_" + (localStorage.getItem("cur_lan") || "jp")],
      year: new Date().getFullYear(),
    };
  },
};
</script>