import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/Admins.vue'),
  },
  
  {
    path: '/user_lists',
    name: 'UserLists',
    component: () => import('../views/UserLists.vue')
  },
  {
    path: '/userApply',
    name: 'UserApply',
    component: () => import('../views/UserApply.vue')
  },
  {
    path: '/userAdd',
    name: 'UserAdd',
    component: () => import('../views/UserAdd.vue')
  },
  {
    path: '/userConfirm',
    name: 'userConfirm',
    component: () => import('../views/UserConfirm.vue')
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import('../views/UserInfo.vue')
  },
  {
    path: '/userProfile',
    name: 'userProfile',
    component: () => import('../views/UserProfile.vue')
  },
  {
    path: '/userEmail',
    name: 'UserEmail',
    component: () => import('../views/UserEmail.vue')
  },
  {
    path: '/super_admin',
    name: 'SuperAdmin',
    component: () => import('../views/SuperAdmin.vue')
  },
  {
    path: '/fileReports',
    name: 'FileReports',
    component: () => import('../views/FileReports.vue')
  },
   {
     path: '/fileVideos',
     name: 'FileVideos',
     component: () => import('../views/FileReports.vue')
   },
   {
    path: '/listReports',
    name: 'ListReports',
    component: () => import('../views/ListReports.vue')
  },
   {
     path: '/listVideos',
     name: 'ListVideos',
     component: () => import('../views/ListReports.vue')
   },
   {
     path: '/marketArea',
     name: 'MarketArea',
     component: () => import('../views/MarketArea.vue')
   },
   {
    path: '/marketAreaID',
    name: 'MarketAreaID',
    component: () => import('../views/MarketAreaID.vue')
  },
  {
    path: '/marketAreaRS',
    name: 'MarketAreaRS',
    component: () => import('../views/MarketAreaRS.vue')
  },
   {
     path: '/download_file',
     name: 'Downloadfile',
     component: () => import('../views/Downloadfile.vue')
   },
   {
    path: '/download_video',
    name: 'Downloadvideo',
    component: () => import('../views/Downloadvideo.vue')
  },
   {
     path: '/faq',
     name: 'FAQ',
     component: () => import('../views/FAQ.vue')
   },
   {
    path: '/commonSet',
    name: 'commonSet',
    component: () => import('../views/CommonSet.vue')
  },{
    path: '/download_data',
    name: 'download_data',
    component: () => import('../views/DownloadData.vue')
  },
  {
    path: '/fee_manager',
    name: 'fee_manager',
    component: () => import('../views/FeeManager.vue')
  },
  {
    path: '/user_confirm_manager',
    name: 'UserConfirmManage',
    component: () => import('../views/UserConfirmManage.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('../views/Analytics.vue')
  },{
    path: '/statistical_duration',
    name: 'statistical_duration',
    component: () => import('../views/StatisticalDuration.vue')
  },{
    path: '/mail_manager',
    name: 'mail_manager',
    component: () => import('../views/CreisMailManager.vue')
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  base: './',
  routes
})

export default router
