//comm js
import axios from 'axios';
import router from '@/router'

function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.href.substr(1).match(reg);
   
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return '';
}
function getNdate(){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    return year+'-'+(month<10 ?'0':'')+month+'-'+(day<10 ?'0':'')+day;
}
function check_login(ischeck){
  let query = window.location.href.split('#');

    if(query.length>1){
        let tem = query[query.length-1].split('&');
        if(tem[0].length>1){
           
            tem.map(ee=>{
                if (ee) {
                let a = ee.split('=');
              
                localStorage.setItem(a[0],a[1])
                
                }
            })
        }
    }
    if (localStorage.getItem('id_token') ) {
        var tokens=localStorage.getItem('id_token');
        var decoded = decodeURIComponent(escape(window.atob(tokens.split('.')[1])));
        decoded=JSON.parse(decoded);
        let name = decoded['email'].split('@')[0]
        let timestamp=(new Date().getTime())/1000;
   
        
        if(timestamp<decoded['exp']+86400){ 
                
            if (decoded ) {
              
                localStorage.setItem('lasttime', timestamp);

                localStorage.setItem('name', name);
                localStorage.setItem('email_verified', decoded['email_verified']);
                localStorage.setItem('email', decoded['email']);
                if (decoded['cognito:groups']) {
                    localStorage.setItem('groups', decoded['cognito:groups'].join(' '));
                }else{
                    localStorage.setItem('groups', 'user');	
                }

                axios
                .get('loginAction', {
                params: {
                email:localStorage.getItem('email'),
                token:localStorage.getItem('id_token'),
                from_site:getQueryString('from_site'),
                }
                })
                .then(response => {
                    
                    if(response.data.status==1){
                        localStorage.setItem('uinfo',JSON.stringify(response.data.data)) 
                        if(((!response.data.data['インダスマーケット'] && !response.data.data['オフィスマーケット'] && !response.data.data['リテールマーケット']) || response.data.data['契約期限 終了日']==null  || response.data.data['契約期限 終了日']<getNdate()) && localStorage.getItem('groups')!='admin' && location.href.indexOf("userApply")==-1 && response.data.data['親ID']==0 && localStorage.getItem('isfirst')!=1){
                            localStorage.setItem('isfirst',1);
                            window.location.href='/userApply';
                        }
                        if(localStorage.getItem("before_url")!=null && localStorage.getItem("before_url")!=''){
                            let to_url=localStorage.getItem("before_url");
                            localStorage.removeItem("before_url");
                            location.href=to_url;
                        }
                    }else{
                        
                        localStorage.setItem('uinfo','') 
                        if(response.data.status==-1){
                            let cur_lan=localStorage.getItem('cur_lan','jp');
                            localStorage.setItem('id_token','') 
                            alert(response.data.data[cur_lan=='jp' ?'menu_name':'menu_name_en']);
                        }
                    } 
                })
                .catch(error => {
                    console.log(error),
                    this.jsonErr = true,
                    this.isLoading = false
                }
                );
            }
        }else{
            localStorage.setItem('id_token',''); 
        }
    
    }
    if (!localStorage.getItem('id_token') && ((router.currentRoute._value.name!="Home" && router.currentRoute._value.name!="userConfirm") || (router.currentRoute._value.name=="Home" && ischeck==1))) {
        let partform=location.href.indexOf('cbre2.creis.jp')>-1?'stage':'live';
        window.location.href = 'https://auth.creis.jp/login?language='+localStorage.getItem('cur_lan')+'&partform='+partform;//'https://auth.cbredata.com/login?client_id=1gcmfopbf7qtttffm228f52657&response_type=token&redirect_uri=https://cbredata.com/jp/index.php';                          
        return false;
    }
    if(!localStorage.getItem('id_token') && ischeck==1){
        return false;
    }
    return true;
}

export {
  check_login,getQueryString,getNdate
}