import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import '../src/assets/css/ctooltip.css';

// Optional, since every component import their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '../src/assets/css/fontawesome-all.min.css'

import './assets/libs/admin-resources/rwd-table/rwd-table.min.css'
import './assets/libs/selectize/css/selectize.bootstrap3.css'
import './assets/css/bootstrap.min.css'
//import './assets/js/app.js'
import './assets/css/app.min.css'
import './assets/css/icons.min.css'
import 'element-plus/lib/theme-chalk/index.css'
//import VueIntro from 'vue-introjs'
import 'intro.js/introjs.css';
//if (document.cookie.split(';').some((item) => item.includes('name='))) {
const app = createApp(App)
let cur_api_host = location.href.indexOf('cbre2.creis.jp') > -1 || location.href.indexOf('localhost') > -1 ? 'https://api2.creis.jp' : 'https://api.creis.jp';
axios.defaults.baseURL = cur_api_host + '/api/v1/research';
localStorage.setItem("cur_api_host", cur_api_host);
axios.defaults.withCredentials = true

axios.interceptors.request.use(
    config => {
        if (config.method == 'post' && config.headers['Content-Type'] != "multipart/form-data") {
            config.data = {
                ...config.data,
                token: localStorage.getItem('id_token'),
                active_email: localStorage.getItem('email'),
            }
        } else if (config.method == 'get') {
            config.params = {
                token: localStorage.getItem('id_token'),
                active_email: localStorage.getItem('email'),
                ...config.params
            }
        }
        return config
    }, function (error) {
        return Promise.reject(error)
    }
)
var initlan = async function init_language() {
    var upd = localStorage.getItem('creis_lan_updated_at');
    var creis_language_data;
    upd = upd == undefined || upd == 'undefined' || upd == null || upd == '' ? '2021-01-01' : upd;
    var response = await axios.get('getLanguage', { params: { updated_at: upd } })
    console.log(upd);
    console.log(response.data);
    if (upd < response.data.last_updated_at || response.data.menu_data_en.user_apply != undefined) {
        localStorage.setItem('creis_lan_updated_at', response.data.last_updated_at);
        let lan_data = JSON.stringify(response.data)
        localStorage.setItem('creis_language_data', lan_data);
        creis_language_data = response.data.data;
    } else {
        creis_language_data = JSON.parse(localStorage.getItem('creis_language_data'));
    }
    // 配置全局变量 页面中使用 inject 接收
    app.provide('global', {
        creis_language_data
    })
    app.use(router).use(VueApexCharts).use(BootstrapVue3).mount('#wrapper')
}
initlan();